.form>input {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-style: none none solid none;
    border-bottom: 1px solid black;
    margin-bottom: .5rem;
}

.form>input:focus {
    border-color: #007eff;
    outline: none;
}

.form>input:disabled {
    background-color: white;
    border-bottom: 1px solid #ccc;
}

.form>select {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-style: none none solid none;
    border-bottom: 1px solid black;
    margin-bottom: .5rem;
}

.form>select:focus {
    border-color: #007eff;
    outline: none;
}

.form>select:disabled {
    background-color: white;
    border-bottom: 1px solid #ccc;
}



.form>.form-outline {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-style: none none solid none;
    border-bottom: 1px solid #ccc;
    margin-bottom: .5rem;
}

.form>input.error {
    border-color: red;
}

.form>label {
    font-weight: bold;
    display: block;
    margin-bottom: 0rem;
}

.feedback {
    color: red;
    margin-top: .25rem;
    font-size: 14px;
}